export default function customFetch (url, method, authorization, body, requestFunction, successFunction, errorFunction, isRequestBodyBlob) {
  console.debug('Calling: ' + url)
  requestFunction && typeof requestFunction === 'function' && (requestFunction())
  let responseOK = false, responseStatus = false
  method = method.toUpperCase()
  return fetch(/[?]/.test(url) ? url + '&_=' + (new Date().getTime()) : url + '?_=' + (new Date().getTime()), {
    method,
    credentials: 'include',
    headers: {
      ...(isRequestBodyBlob ? null : {
        'Content-Type': 'application/json'
      }),
      ...(authorization ? {
        'Authorization': `Bearer ${authorization}`
      }: null)
    },
    ...method !== 'GET' && method !== 'DELETE' && body && { body: isRequestBodyBlob ? body : JSON.stringify(body) }
  })
    .then(response => {
      responseStatus = response.status
      if (response.status === 401) {
        responseOK = 'unauth'
        return
      }
      let contentType = response.headers.get('Content-Type')
      if (response.ok) { responseOK = true }
      if (/json/.test(contentType) || /text\/html/.test(contentType)) { return response.json() }
      if (/csv/.test(contentType) || /sheet/.test(contentType)) { return response.blob() }
      throw new Error()
    })
    .then(json => {
      if (responseOK === 'unauth') {
        console.debug('Login session expired')
        throw new Error('Unauthorized')
      }
      console.debug(json)
      if (responseOK) {
        successFunction && typeof successFunction === 'function' && (successFunction(json))
      } else {
        throw new Error(json && json.error)
      }
    })
    .catch(err => {
      console.debug(err)
      err.status = responseStatus
      errorFunction && typeof errorFunction === 'function' && (errorFunction(err))
    })
}
