const prefix = '/api'
// const prefix = 'https://cnr-tcdm.candrholdings.com/api'
// const prefix = 'http://35.241.95.28/api'

const api = {
  ticket: prefix + '/ticket',
  // getDashboardsByUser: prefix + '/dashboards',
  getUserAppData: prefix + '/user_appdata',
  getReminder: prefix + '/messages',
  checkApiServerAccess: prefix + '/check_api_access',
  setApiServerPassword: prefix + '/set_api_password'
}

export default api
