import { Auth0Lock } from "auth0-lock";
import api from "../api";
import customFetch from "../resources/customFetch";
import config from "../resources/config";

const {
  CREDENTIALS_KEY,
  auth0Config,
  defaultExpiresIn,
  sampleProfile,
  auth0Options,
  devEnv,
} = config;

const lock = new Auth0Lock(
  auth0Config.clientId,
  auth0Config.domain,
  auth0Options
);

const createLoginStartAction = () => ({
  type: "LOGIN_START",
});

const createLoginErrorAction = (error) => ({
  type: "LOGIN_FAILED",
  payload: {
    error: error,
  },
});

// const createLoginsuccessAction = (profile, username, accessToken) => ({
//   type: 'LOGIN_SUCCESS',
//   payload: {
//     profile,
//     username,
//     accessToken
//   }
// })

const createAuth0LoginSuccessAction = ({
  profile,
  loggedInUser,
  accessToken,
  dashboards,
  dashboardList,
  whatIfList,
  idleTimeout,
  expiry,
}) => ({
  type: "AUTH0_SUCCESS",
  payload: {
    profile,
    loggedInUser,
    accessToken,
    dashboards,
    dashboardList,
    whatIfList,
    idleTimeout,
    expiry,
  },
});

export const createLogoutAction = () => ({
  type: "LOGOUT",
});

const startRestoreCredentials = () => ({
  type: "START_RESTORE_CREDENTIALS",
});

const restoreCredentials = ({
  profile,
  loggedInUser,
  accessToken,
  dashboards,
  dashboardList,
  whatIfList,
  idleTimeout,
  lastActiveTime,
  expiry,
}) => ({
  type: "RESTORE_CREDENTIALS",
  payload: {
    profile,
    loggedInUser,
    accessToken,
    dashboards,
    dashboardList,
    whatIfList,
    idleTimeout,
    lastActiveTime,
    expiry,
  },
});

export const loginAuth0 = () => {
  return (dispatch, getState) => {
    dispatch(createLoginStartAction());
    if (devEnv) {
      return dispatch(
        createAuth0LoginSuccessAction({
          profile: sampleProfile,
          loggedInUser: extractUsername(sampleProfile.sub),
          accessToken: sampleProfile.accessToken,
          dashboards: sampleProfile.dashboards,
          dashboardList: sampleProfile.dashboardList,
          whatIfList: sampleProfile.whatIfList,
          idleTimeout: sampleProfile.webIdleTimeout,
          expiry: sampleProfile.expiresIn * 1000,
        })
      );
    }
    lock.on("authenticated", (authResult) => {
      if (!authResult) {
        dispatch(createLoginErrorAction("Cancelled"));
      } else {
        console.debug(`authentication result: ${JSON.stringify(authResult)}`);
        console.debug(authResult);
        lock.getUserInfo(authResult.accessToken, (error, profile) => {
          if (error) {
            console.debug(error);
            dispatch(createLoginErrorAction("Login failed"));
          } else {
            console.debug(profile);
            const loggedInUser = extractUsername(profile.sub);

            customFetch(
              api.getUserAppData,
              "GET",
              authResult.accessToken,
              null,
              () => {},
              (json) => {
                const expiry =
                  new Date().getTime() +
                  (json.expiry || defaultExpiresIn) * 60 * 1000;
                const currentDateTime = new Date().getTime();
                window.localStorage.setItem(
                  CREDENTIALS_KEY,
                  JSON.stringify({
                    profile,
                    accessToken: authResult.accessToken,
                    dashboards: json.dashboards,
                    dashboardList: json.dashboardList,
                    whatIfList: json.whatIfList,
                    idleTimeout: json.webIdleTimeout,
                    lastActiveTime: currentDateTime,
                    expiry,
                  })
                );
                lock.hide();
                dispatch(
                  createAuth0LoginSuccessAction({
                    profile,
                    loggedInUser,
                    accessToken: authResult.accessToken,
                    dashboards: json.dashboards,
                    dashboardList: json.dashboardList,
                    whatIfList: json.whatIfList,
                    idleTimeout: json.webIdleTimeout,
                    lastActiveTime: currentDateTime,
                    expiry,
                  })
                );
              },
              (err) => {
                lock.hide();
                dispatch(
                  createAuth0LoginSuccessAction({
                    profile,
                    loggedInUser,
                    accessToken: authResult.accessToken,
                    dashboards: [],
                    dashboardList: [],
                    whatIfList: [],
                    idleTimeout: 0,
                    lastActiveTime: new Date().getTime(),
                    expiry: 0,
                  })
                );
              }
            );

            // window.localStorage.setItem(CREDENTIALS_KEY, JSON.stringify({profile, accessToken: authResult.accessToken}))
            // lock.hide()
            // dispatch(createAuth0LoginSuccessAction(profile, loggedInUser, authResult.accessToken))
          }
        });
      }
    });
    lock.show(auth0Options);
  };
};

export const markLastActiveTime = () => ({
  type: "MARK_LAST_ACTIVE_TIME",
  payload: {
    lastActiveTime: new Date().getTime(),
  },
});

// export const markAutoLogout = (status) => ({
//   type: 'AUTO_LOGOUT',
//   payload: {
//     status
//   }
// })

export const logout = (autoLogout) => {
  window.localStorage.removeItem(CREDENTIALS_KEY);
  return (dispatch, getState) => {
    dispatch(createLogoutAction());
    lock.logout({
      federated: true,
      returnTo: autoLogout
        ? `${window.document.location.href.split(/#/)[0]}#timeout`
        : window.document.location.href.split(/#/)[0],
    });
  };
};

export const checkSavedSignIn = () => {
  console.debug("check saved profile from local storage");
  return (dispatch, getState) => {
    dispatch(startRestoreCredentials());
    if (devEnv) {
      return dispatch(
        restoreCredentials({
          profile: sampleProfile,
          loggedInUser: extractUsername(sampleProfile.sub),
          accessToken: sampleProfile.accessToken,
          dashboards: sampleProfile.dashboards,
          dashboardList: sampleProfile.dashboardList,
          whatIfList: sampleProfile.whatIfList,
          idleTimeout: sampleProfile.webIdleTimeout,
          lastActiveTime: new Date().getTime(),
          expiry: sampleProfile.expiresIn * 1000,
        })
      );
    }
    const credentialsJson = window.localStorage.getItem(CREDENTIALS_KEY);
    try {
      const {
        profile,
        accessToken,
        dashboards,
        dashboardList,
        whatIfList,
        idleTimeout,
        lastActiveTime,
        expiry,
      } = JSON.parse(credentialsJson);
      console.debug(`profile restored:  ${JSON.stringify(profile)}`);
      dispatch(
        restoreCredentials({
          profile,
          loggedInUser: extractUsername(profile.sub),
          accessToken,
          dashboards,
          dashboardList,
          whatIfList,
          idleTimeout,
          lastActiveTime,
          expiry,
        })
      );
    } catch (error) {
      console.debug(`No credentials found in storage`);
      dispatch(
        restoreCredentials({
          profile: null,
          loggedInUser: null,
          accessToken: null,
          dashboards: [],
          dashboardList: [],
          whatIfList: [],
          idleTimeout: 0,
          lastActiveTime: new Date().getTime(),
          expiry: 0,
        })
      );
    }
  };
};

export const createUpdateWhatIfAction = (whatIfList) => ({
  type: "UPDATE_WHATIF",
  payload: {
    whatIfList,
    whatIfListLastUpdated: new Date(),
  },
});

export const updateWhatIf = () => {
  return (dispatch, getState) => {
    const state = getState();
    const accessToken = state.login && state.login.accessToken;
    customFetch(
      api.getUserAppData,
      "GET",
      accessToken,
      null,
      null,
      (json) => {
        if (!json || !json.whatIfList) {
          return;
        }
        const credentialsJson = window.localStorage.getItem(CREDENTIALS_KEY);
        try {
          const credentials = JSON.parse(credentialsJson);
          window.localStorage.setItem(
            CREDENTIALS_KEY,
            JSON.stringify({
              ...credentials,
              whatIfList: json.whatIfList,
            })
          );
          console.debug(`whatif updated: ${JSON.stringify(json.whatIfList)}`);
          dispatch(createUpdateWhatIfAction(json.whatIfList));
        } catch (err) {}
      },
      null
    );
  };
};

export const createApiServerAccessStartAction = () => ({
  type: "API_SERVER_ACCESS_CHECK_START",
});

export const createApiServerAccessSuccessAction = (granted) => ({
  type: "API_SERVER_ACCESS_CHECK_SUCCESS",
  payload: {
    granted
  }
});

export const createApiServerAccessFailureAction = (error) => ({
  type: "API_SERVER_ACCESS_CHECK_FAILURE",
  payload: {
    error: error.toString(),
  },
});


export const checkApiServerAccess = () => {
  return (dispatch, getState) => {
    const state = getState();
    const accessToken = state.login && state.login.accessToken;
    dispatch(createApiServerAccessStartAction());
    customFetch(
      api.checkApiServerAccess,
      "GET",
      accessToken,
      null,
      null,
      (json) => {
        dispatch(createApiServerAccessSuccessAction(json.granted));
      },
      (error) => {
        console.error(error);
        dispatch(createApiServerAccessFailureAction(error));
      }
    );
  };
};

export const createApiServerPasswordResetStartAction = () => ({
  type: "API_SERVER_PASSWORD_RESET_START",
});

export const createApiServerPasswordResetSuccessAction = () => ({
  type: "API_SERVER_PASSWORD_RESET_SUCCESS",
});

export const createApiServerPasswordResetFailureAction = (error) => ({
  type: "API_SERVER_PASSWORD_RESET_FAILURE",
  payload: {
    error: error.toString(),
  },
});

export const setApiServerPassword = (password) => {
  return (dispatch, getState) => {
    const state = getState();
    const accessToken = state.login && state.login.accessToken;
    dispatch(createApiServerPasswordResetStartAction());
    customFetch(
      api.setApiServerPassword,
      "POST",
      accessToken,
      { password },
      null,
      (json) => {
        dispatch(createApiServerPasswordResetSuccessAction());
      },
      (error) => {
        console.error(error);
        dispatch(createApiServerPasswordResetFailureAction(error));
      }
    );
  };
};

const extractUsername = (tokenSubject) =>
  tokenSubject && tokenSubject.split("|").slice(-1)[0];
