import React, { Component } from 'react';
import { ListItem, List, Typography } from '@material-ui/core';
import moment from 'moment';

class Banner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showBanner: true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('this.props.url', this.props.url);
    if (this.props.url !== prevProps.url) {
      this.setState({ showBanner: true });
    }
  }

  handleDoubleClick() {
    this.setState({ showBanner: false });
  }

  render() {
    return (
      <div>
        {
          this.state.showBanner &&
          <Typography component="div"
                      style={{
                        backgroundColor: '#e54d4d',
                        height: 'auto',
                        width: '100%',
                        // padding: '0.2rem',
                      }}
            // onDoubleClick={() => this.handleDoubleClick()}
          >
            <List>
              {(this.props.data || []).map((item, index) =>
                <ListItem key={index} style={{
                  flexDirection: 'row',
                  paddingTop: 2,
                  paddingBottom: 2,
                  // marginTop: '-0.5rem',
                  // marginTop: '-8px',
                  // marginBottom: '-0.85rem',
                  textAlign: 'justify',
                }}>
                  <a style={{
                    fontSize: 8,
                    color: 'yellow',
                    alignSelf: 'start',
                    paddingTop: '0.5rem',
                    marginRight: 15,
                  }}>{'\u2B24'}</a>
                  <span>
                  <a style={{
                    color: '#ffffff',
                    // paddingRight: 15,
                    // paddingLeft: 15,
                    marginRight: 15,
                    textDecorationStyle: 'solid',
                    fontSize: 18,
                  }}>{item.bannertext.trim()}
                  </a>
                  <a style={{
                    color: '#ffffff',
                    fontSize: 13,
                  }}>Until {moment(item.validto, moment.defaultFormat).utc().format('DD/MM HH:mm')}</a>
                    </span>
                </ListItem>,
              )}
            </List>
          </Typography>
        }

      </div>
    );
  }

}

export default Banner;