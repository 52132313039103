import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Button, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import hkairportLogo from '../images/hkairport-curve-logo.png'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function TopBar({ onDrawerClick }) {
  const classes = useStyles();
  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={onDrawerClick}>
          <MenuIcon/>
        </IconButton>
        <img src={hkairportLogo} style={{height: 30, marginRight: 10}}/>
        <Typography variant="h6" className={classes.title}>
          Terminal Collaboration Decision Making System
        </Typography>
        {/*<Button color="inherit">
          <NotificationsIcon/>
        </Button>*/}
      </Toolbar>
    </AppBar>
  )
}

export default TopBar