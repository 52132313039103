const scenarioTemplate = {
  normal: {
    name: 'Normal',
    code: 'normal',
    value: 'scenariotype=default&element_name=Forecast',
    appSelection: 'app_selection=default'
  },
  whatIf: {
    name: 'What If',
    code: null,
    value: 'scenariotype=WhatIf&element_name=Scenario',
    appSelection: 'app_selection=###WHATIF###'
  }
}

export default scenarioTemplate