import api from '../api';
import customFetch from '../resources/customFetch';

export const createStartTicketAction = () => ({
  type: 'GET_TICKET_START'
});

export const createTicketSuccessAction = (dashboard, json) => ({
  type: 'GET_TICKET_SUCCESS',
  payload: {
    dashboard,
    ticket: json.ticket
  }
});

export const createTicketFailureAction = (error) => ({
  type: 'GET_TICKET_FAILED',
  payload: {
    error
  }
});

export const changeDashboard = (dashboard) => ({
  type: 'CHANGE_DASHBOARD',
  payload: {
    dashboard
  }
});

export const changeScenario = (scenario) => ({
  type: 'CHANGE_SCENARIO',
  payload: {
    scenario
  }
});

export const resetScenario = () => ({
  type: 'RESET_SCENARIO'
});

export const requestReminders = () => ({
  type: 'GET_REMINDERS'
});

export const showSuccessReminders = (data) => ({
  type: 'SHOW_REMINDERS_SUCCESS',
  payload: {
    reminders: data.reminders
  }
});

export const showErrorReminders = () => ({
  type: 'SHOW_REMINDERS_FAILED'
});

export const getReminders = () => {
  return (dispatch, getState) => {
    const username = getState().login.loggedInUser;
    const accessToken = getState().login.accessToken;

    fetch(api.getReminder, {
      method: 'GET',
      // credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
    })
      .then(async (r) => {
        if (r.status >= 400) {
          throw new Error('Bad response from server, cannot get reminders');
        }
        const resp = await r.json();
        console.log('resp', resp);
        dispatch(showSuccessReminders(resp || []));
      })
      .catch((e) => {
        console.error(e);
      });
  };
};

export const getTrustedTicket = (dashboard) => {
  return (dispatch, getState) => {
    const username = getState().login.loggedInUser;
    const accessToken = getState().login.accessToken;
    customFetch(
      api.ticket,
      'GET',
      accessToken,
      null,
      () => dispatch(createStartTicketAction()),
      json => dispatch(createTicketSuccessAction(dashboard, json)),
      err => dispatch(createTicketFailureAction(err && err.toString() || 'Cannot establish trusted authentication'))
    );
  };
};