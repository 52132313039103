import React, { Component } from 'react';
import { ListItem, List, Typography } from '@material-ui/core';
import moment from 'moment';

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showBanner: true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('this.props.url', this.props.url);
    if (this.props.url !== prevProps.url) {
      this.setState({showBanner: true});
    }
  }

  handleDoubleClick() {
    this.setState({showBanner: false});
  }

  render() {
    return (
      <div>
        {
          this.state.showBanner &&
          <Typography component="div"
                      style={{
                        // backgroundColor: '#e54d4d',
                        height: 'auto',
                        width: '100%',

                        // border: 'solid',
                        // borderColor: '#a3a3a3',
                      }}
            // onDoubleClick={() => this.handleDoubleClick()}
          >
            <List>
              {(this.props.data || []).map((item, index) =>
                <ListItem key={index} style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}>
                  <a style={{
                    // color: '#ffffff',
                    // paddingRight: 15,
                    // paddingLeft: 15,
                    color: '#a3a3a3',
                    marginRight: 15,
                    textDecorationStyle: 'solid',
                    fontSize: 15,
                  }}>{item.disclaimertext.trim()}</a>
                </ListItem>,
              )}
            </List>
          </Typography>
        }
      </div>
    );
  }
}

export default Footer;