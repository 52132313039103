import React, { Component } from 'react';
import { Paper, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import HkAirportLogo from '../images/HkAirportLogo'
import { connect } from 'react-redux';
import {
  // markAutoLogout,
  loginAuth0
} from '../actions/loginActions'

const mapStateToProps = state => ({
  login: state.login
})

const mapDispatchToProps = {
  // markAutoLogout,
  loginAuth0
}

const AutoLogoutAlertDialog = ({open, onClose}) => (<Dialog
  open={open}
  onClose={onClose}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
  disableBackdropClick
  disableEscapeKeyDown
>
  <DialogTitle>{'Alert'}</DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
      {'Previous session has reached maximum idle period. You have been logged out automatically.'}
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button autoFocus onClick={onClose} color="primary">
      OK
    </Button>
  </DialogActions>
</Dialog>)

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
      this._handleSignIn()
  }

  componentDidUpdate(prevProps, prevState) {
  }

  _handleSignIn() {
    this.props.loginAuth0()
    const hash = document.location.href.split(/#/)[1]
    if (hash === 'timeout') {
      this.setState({
        showAutoLogoutAlert: true
      })
    }
  }

  render() {
    const {
      showAutoLogoutAlert
    } = this.state
    const {
      loginStarted,
      loginFailed
    } = this.props.login
    const disabled = loginStarted

    return (
      <div style={{height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <div style={{marginTop: 50}}>
          <HkAirportLogo/>
        </div>
        <h1 style={{flex: 1, margin: 20}}>Terminal Collaboration Decision Making System</h1>
        {!loginStarted && <CircularProgress size={100} style={{margin: 40}}/>}
        <div id='auth0-container' style={{flex: 4, width: 480, height: 100, zoom: '150%', justifyContent: 'flex-start', visibility: this.state.showAutoLogoutAlert ? 'hidden' : 'visible'}}>
        </div>
        <AutoLogoutAlertDialog
          open={showAutoLogoutAlert}
          onClose={() => {
            this.setState({
              showAutoLogoutAlert: false
            })
            window.history.pushState('', document.title, window.location.href.split('#')[0])
          }}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
