import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Icon,
  CircularProgress,
  Snackbar,
  Switch,
  FormControlLabel,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import RestoreIcon from "@material-ui/icons/Restore";
import ScenarioPicker from "../components/ScenarioPicker";
import MuiAlert from "@material-ui/lab/Alert";
// import {
//   markLastActiveTime
// } from '../actions/loginActions'
import {
  getTrustedTicket,
  resetScenario,
  changeScenario,
  getReminders,
} from "../actions/dashboardActions";
import scenarioTemplate from "../resources/scenarioTemplate";
import Banner from "./Banner-web";
import Footer from "./Footer-web";

const mapStateToProps = (state) => ({
  login: state.login,
  dashboard: state.dashboard,
});

const mapDispatchToProps = {
  // markLastActiveTime,
  getTrustedTicket,
  resetScenario,
  changeScenario,
  getReminders,
};

const refreshIntervalLocalStorageKey = "aahk.tcdm.refreshInterval";
const refreshRates = [1, 3, 5, 15, 30, 60];
const defaultDataRefreshInterval = 3;

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

const makeScenarioList = (scenarioTemplate, whatIfList) => {
  return [
    scenarioTemplate.normal,
    ...(whatIfList &&
      whatIfList.map((w) => ({
        ...scenarioTemplate.whatIf,
        name: w.datasetId,
        appSelection: scenarioTemplate.whatIf.appSelection.replace(
          /###WHATIF###/g,
          w.datasetId
        ),
        code: w.datasetId.replace(/\s+/g, "-"),
      }))),
  ];
};

class DataView extends Component {
  constructor(props) {
    super(props);
    this._dashboardRef = React.createRef();
    this._screenRef = React.createRef();
    this._tableauRef = React.createRef();
    this._handleAutoRefreshToggle = this._handleAutoRefreshToggle.bind(this);
    this._resetRefreshInterval = this._resetRefreshInterval.bind(this);
    this._initializeViz = this._initializeViz.bind(this);
    this.reload = this.reload.bind(this);
    this._viz = null;
    this.state = {
      refreshResponse: false,
      canGoBack: false,
      canGoForward: false,
      serverUrl: null,
      error: null,
      dashboardHeight: 0,
      autoRefresh: true,
      refreshInterval: defaultDataRefreshInterval,
    };
  }

  componentDidMount() {
    this.props.dashboard.selectedDashBoard &&
      this.props.getTrustedTicket(this.props.dashboard.selectedDashBoard);
    this.props.getReminders();
    // this.navigationEventListener = Navigation.events().bindComponent(this);
    const refreshIntervalFromStorage = parseInt(
      localStorage.getItem(refreshIntervalLocalStorageKey) ||
        defaultDataRefreshInterval.toString()
    );
    this.setState({
      refreshInterval: refreshIntervalFromStorage,
    });
  }

  componentWillUnmount() {
    this._autoTimer && clearTimeout(this._autoTimer);
  }

  componentDidUpdate(prevProps, prevState) {
    const { dashboardList, whatIfList } = this.props.login;
    const {
      selectedDashBoard,
      selectedScenario,
      getTicketStarted,
      getTicketFailed,
      tickets,
      scenarioTemplate,
    } = this.props.dashboard;
    const scenarios = makeScenarioList(scenarioTemplate, whatIfList);
    console.debug(
      `in componentDidUpdate, scenarios = ${JSON.stringify(scenarios)}`
    );
    if (
      selectedDashBoard &&
      prevProps.dashboard.getTicketStarted &&
      !getTicketStarted &&
      !getTicketFailed &&
      tickets &&
      tickets[selectedDashBoard]
    ) {
      const targetScenario = scenarios.find((s) => s.code === selectedScenario);
      if (!targetScenario) {
        this.props.resetScenario();
      } else {
        const targetDashboard =
          dashboardList &&
          dashboardList.find((db) => selectedDashBoard === db.code);
        this.setState({ dashboardHeight: targetDashboard.height_web });
        const serverUrl =
          targetDashboard &&
          targetDashboard.url &&
          targetDashboard.url
            .replace(/###TICKET###/, tickets[selectedDashBoard])
            .replace(
              /###SCENARIO###/,
              targetScenario.value ? `${targetScenario.value}&` : ""
            )
            .replace(
              /###PARAMETERS###/,
              `:toolbar=no&:device=desktop&:showVizHome=no&:embed=y&:showAppBanner=false&`
            )
            .replace(
              /###WHATIF###/,
              `${encodeURI(targetScenario.appSelection)}`
            );
        console.debug(
          `in componentDidMount, ticket received for dashboard "${selectedDashBoard}"`
        );
        console.debug(`serverUrl = ${serverUrl}`);
        this.setState({
          serverUrl,
        });
      }
    }
    if (
      prevProps.dashboard.selectedDashBoard !== selectedDashBoard ||
      prevProps.dashboard.selectedScenario !== selectedScenario ||
      (selectedDashBoard &&
        prevProps.dashboard.getTicketStarted &&
        !getTicketStarted &&
        !getTicketFailed &&
        !tickets[selectedDashBoard])
    ) {
      // in the above if statement, the last case being that a dashboard hyperlink is clicked. So the previous ticket isn't stored in the target dashboard. just retrieve another one
      this.props.getTrustedTicket(selectedDashBoard);
    }
    if (
      this.state.serverUrl &&
      prevState.serverUrl !== this.state.serverUrl &&
      this._tableauRef &&
      this._tableauRef.current
    ) {
      this._initializeViz();
    }
  }

  _initializeViz() {
    const options = {
      width: this._tableauRef.current.offsetWidth,
      height: this._tableauRef.current.offsetHeight,
      hideTabs: true,
      hideToolbar: true,
      onFirstInteractive: () => {
        console.debug(`tableau object loaded`);
        this.state.autoRefresh && this._handleAutoRefreshToggle(true);
      },
    };
    try {
      this._viz && this._viz.dispose();
      // this._viz = new window.tableauSoftware.Viz(this._tableauRef.current, this.state.serverUrl, options);
    } catch (error) {
      console.debug(`error: ${error.toString()}`);
      this.setState({
        error: error && error.toString(),
      });
    }
    this._viz = new window.tableauSoftware.Viz(
      this._tableauRef.current,
      this.state.serverUrl,
      options
    );
  }

  _handleAutoRefreshToggle(checked) {
    this.setState(
      {
        autoRefresh: checked,
        autoRefreshChanged: true,
      },
      () => {
        if (!checked && this._autoTimer) {
          console.debug(`toggle off: clear existing timer`);
          clearTimeout(this._autoTimer);
        } else if (checked) {
          if (this._autoTimer) {
            console.debug(
              `toggle on: clear existing timer before set up a new one`
            );
            clearTimeout(this._autoTimer);
          }
          this._autoTimer = setTimeout(
            this.reload,
            this.state.refreshInterval * 60 * 1000
          );
        }
      }
    );
  }

  _handleRefreshIntervalChange(newRefreshInterval) {
    console.log(
      `_handleRefreshIntervalChange(), newRefreshInterval = ${newRefreshInterval}`
    );
    const refreshInterval = Math.floor(
      newRefreshInterval > 1 ? newRefreshInterval : 1
    );
    console.log(
      `_handleRefreshIntervalChange(), refreshInterval = ${refreshInterval}`
    );
    this.setState({
      refreshInterval,
    });
    localStorage.setItem(
      refreshIntervalLocalStorageKey,
      refreshInterval.toString()
    );
    if (this.state.autoRefresh) {
      console.log(
        `_handleRefreshIntervalChange(), auto refresh effective, reschedule`
      );
      this._handleAutoRefreshToggle(true);
    }
  }

  _resetRefreshInterval() {
    this.setState({
      refreshInterval: defaultDataRefreshInterval,
    });
    localStorage.setItem(
      refreshIntervalLocalStorageKey,
      defaultDataRefreshInterval.toString()
    );
    if (this.state.autoRefresh) {
      this._handleAutoRefreshToggle(true);
    }
  }

  reload() {
    const { loggedInUser, lastActiveTime, idleTimeout, expiry } =
      this.props.login;
    const idleTimeoutDatetime = lastActiveTime + idleTimeout * 1000;
    const currentDatetime = new Date().getTime();
    if (
      loggedInUser &&
      idleTimeout > 0 &&
      currentDatetime > idleTimeoutDatetime
    ) {
      console.debug(
        `current (${currentDatetime}) > idle time (${idleTimeoutDatetime}). Max idle period reached. Will not reload any more`
      );
      return;
    }
    this.props.getReminders();
    console.debug(`reload dashboard`);
    // this.props.markLastActiveTime()
    if (this._autoTimer) {
      console.debug(`clear existing timer`);
      clearTimeout(this._autoTimer);
    }
    if (this._viz) {
      console.debug(`calling tableau data refresh`);
      this.setState(
        {
          refreshResponse: true,
        },
        () => {
          this._viz.refreshDataAsync();
        }
      );
    } else {
      console.debug(`no tableau object created`);
    }
    this.props.onReload && this.props.onReload();
    if (this.state.autoRefresh) {
      console.debug(`schedule next reload`);
      this._autoTimer = setTimeout(
        this.reload,
        this.state.refreshInterval * 60 * 1000
      );
    }
  }

  render() {
    const { dashboard } = this.props;
    const { dashboardList, whatIfList } = this.props.login;
    const {
      selectedDashBoard,
      selectedScenario,
      scenarioTemplate,
      tickets,
      getTicketStarted,
      getTicketFailed,
      reminders,
    } = dashboard;
    const scenarios = makeScenarioList(scenarioTemplate, whatIfList);
    const scenario = scenarios.find((s) => s.code === selectedScenario);
    const dashboardObject =
      dashboardList && dashboardList.find((s) => s.code === selectedDashBoard);
    const scenarioName = (scenario && scenario.name) || "";
    const dashboardName =
      (dashboardObject &&
        `${dashboardObject.category} / ${dashboardObject.name}`) ||
      "";
    console.debug(`in render(), serverUrl = ${this.state.serverUrl}`);
    // this._dashboardRef && console.debug(this._dashboardRef)
    return (
      <div ref={this._screenRef} style={{ flex: 1 }}>
        <div
          ref={this._dashboardRef}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              // justifyContent: 'space-between',
              alignItems: "center",
            }}
          >
            <Button
              size="small"
              variant="contained"
              startIcon={<RefreshIcon />}
              onClick={() => this.reload()}
            >
              Refresh data
            </Button>
            <FormControlLabel
              style={{ marginLeft: 12 }}
              control={
                <Switch
                  size="small"
                  checked={this.state.autoRefresh}
                  onChange={(event) =>
                    this._handleAutoRefreshToggle(event.target.checked)
                  }
                  value="autoRefresh"
                  inputProps={{ "aria-label": "auto-refresh checkbox" }}
                />
              }
              label="Auto Refresh"
            />
            <FormControl style={{ minWidth: 120 }}>
              <Select
                labelId="refresh-interval-label"
                value={this.state.refreshInterval}
                onChange={(event) =>
                  this._handleRefreshIntervalChange(event.target.value)
                }
                startAdornment={
                  <Typography variant="caption" style={{ marginRight: 4 }}>
                    Every
                  </Typography>
                }
              >
                {refreshRates.map((refreshRate) => (
                  <MenuItem key={refreshRate} value={refreshRate}>
                    {`${refreshRate} min`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              size="small"
              variant="contained"
              startIcon={<RestoreIcon />}
              onClick={this._resetRefreshInterval}
              style={{ fontSize: 12, textTransform: "none", marginLeft: 8 }}
            >
              Reset default
            </Button>
          </div>
          <div style={{ fontWeight: "bold" }}>{dashboardName}</div>
          <div>
            Scenario:{" "}
            <ScenarioPicker
              scenarios={scenarios}
              selectedScenario={selectedScenario}
              onChange={(scenario) => this.props.changeScenario(scenario)}
            />
          </div>
        </div>
        {reminders && reminders.header && reminders.header.length > 0 && (
          <div
            style={{
              paddingTop: 5,
              marginTop: 5,
              position: "sticky",
              top: "0rem",
            }}
          >
            <Banner url={this.state.serverUrl} data={reminders.header} />
            {/*{this.state.dashboardHeight}*/}
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "3em",
          }}
        >
          {getTicketStarted && <CircularProgress />}
          {selectedDashBoard &&
            !getTicketStarted &&
            !getTicketFailed &&
            this.state.serverUrl &&
            this.state.dashboardHeight && (
              <div
                id="tableau-embedded"
                ref={this._tableauRef}
                style={{
                  width:
                    this._dashboardRef &&
                    this._dashboardRef.current.clientWidth - 20,
                  height: this.state.dashboardHeight,
                }}
              />
            )}
          {getTicketFailed && (
            <div>
              {`Connectivity problem with Tableau server. Please check and try again later.`}
            </div>
          )}
        </div>
        {reminders && reminders.footer && reminders.footer.length > 0 && (
          <div style={{ marginTop: 10 }}>
            <Footer url={this.state.serverUrl} data={reminders.footer} />
          </div>
        )}
        {this.state.error && (
          <Snackbar
            open={!!this.state.error}
            autoHideDuraction={5000}
            onClose={() => this.setState({ error: null })}
            message={this.state.error}
          />
        )}
        {this.state.refreshResponse && (
          <Snackbar
            open={this.state.refreshResponse}
            autoHideDuration={4000}
            onClose={() =>
              this.setState({
                refreshResponse: false,
              })
            }
          >
            <Alert
              onClose={() => this.setState({ refreshResponse: false })}
              severity="success"
            >
              Fetching latest data...
            </Alert>
          </Snackbar>
        )}
        {this.state.autoRefreshChanged && (
          <Snackbar
            open={this.state.autoRefreshChanged}
            autoHideDuration={2000}
            onClose={() =>
              this.setState({
                autoRefreshChanged: false,
              })
            }
          >
            <Alert
              onClose={() => this.setState({ autoRefreshChanged: false })}
              severity="success"
            >
              {`${
                this.state.autoRefresh
                  ? `Data will be auto-refreshed every ${this.state.refreshInterval} min`
                  : "Auto refresh is turned off"
              }`}
            </Alert>
          </Snackbar>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataView);
