const productionEnv = process.env.REACT_APP_BUILD === 'PROD';
const uatEnv = process.env.REACT_APP_BUILD === 'UAT';
const devEnv = !productionEnv && !uatEnv;

const CREDENTIALS_KEY = 'aahk.tcdm.credentials';
const AGREEMENT_KEY = 'aahk.tcdm.agreement';
const MESSAGES_KEY = 'aahk.tcdm.messages';
const SETTINGS_KEY = 'aahk.tcdm.settings';

// const tableau = (productionEnv || uatEnv) ? 'https://hkia.oneas1a.com' : 'http://34.80.129.198'
// const tableauSite = (productionEnv || uatEnv) ? 'TCDM' : ''
const tableau = 'https://hkia.oneas1a.com';
const tableauSite = 'TCDM';

const auth0Config = {
  clientId: 'cujI0zABFbm1TlvOtBoiUuEft27C02Az',
  domain: 'tcdm-mobile.hkairport.com'
};

const defaultExpiresIn = 300;

const sampleProfile = {
  'sub': 'adfs|aahk-tcdm-adfs|JOHN_DOE',
  'name': 'John Doe (C&R)',
  'picture': null,
  'accessToken': 'RCUXkwYnMAcPEby4Xdg90TKwT4bks6Vn',
  'dashboards': [
    'apm-arrival',
    'apm-departure',
    'apm-skypier-sta',
    'apron-bus-departure',
    'arrival-airside-trolley',
    'arrival-general-cleaning',
    'check-in-csbd-icuss',
    'check-in-departure',
    'check-in-desk-transfer',
    'check-in-skypier',
    'cleaning-food-court',
    'cleaning-general',
    'cleaning-toilet',
    'departure-airside',
    'general-overview',
    'general-overview-arrival',
    'immigration-arrival',
    'immigration-departure',
    'land-transport-departure',
    'retail-airside',
    'security-check-departure',
    'security-check-skypier',
    'security-check-transfer',
    'security-screen-departure',
    'security-screen-skypier',
    'security-screen-transfer',
    'skypier-sea-to-air',
    'skypier-ticketing',
    'toilet-cleaning-arrival',
    'trolley-airside',
    'trolley-landside'
  ],
  'groups': [
    'arrivals-airside',
    'arrivals-landside',
    'check-in-csbd-icuss',
    'check-in-departure',
    'check-in-skypier',
    'cleaning-food-court',
    'cleaning-general',
    'cleaning-toilet',
    'departure-airside',
    'departure-landside',
    'immigration-arrival',
    'immigration-departure',
    'land-transport-arrival',
    'land-transport-departure',
    'retail-landside',
    'retail-airside',
    'security-check-departure',
    'security-check-skypier',
    'security-check-transfer',
    'security-screen-departure',
    'security-screen-skypier',
    'security-screen-transfer',
    'skypier-air-to-sea',
    'skypier-sea-to-air',
    'trolley-airside',
    'trolley-landside',
    'apm-departure',
    'apron-bus-departure',
    'general-overview',
  ],
  'expiresIn': 7200,
  'webIdleTimeout': 999999999999,
  'dashboardList': [
    {
      'category': 'Overview',
      'name': 'Departure Landside',
      'code': 'general-overview',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/general_overview_twbx/general_overview?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 2400,
      'height_web': 3750,
    },
    {
      'category': 'Overview',
      'name': 'Departure Airside',
      'code': 'departure-airside',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/overview_departure_airside_twbx/overview_departure_airside?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'Overview',
      'name': 'Arrival Airside',
      'code': 'general-overview-arrival',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/general_arrival_and_cleaning_twbx/general_overview_arrival?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'Check-in',
      'name': 'T1 Departure',
      'code': 'check-in-departure',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/checkin_twbx/checkin?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'Check-in',
      'name': 'T1 Departure, CSBD, iCUSS',
      'code': 'check-in-csbd-icuss',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/checkin_twbx/checkin_csbd_icuss?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'Check-in',
      'name': 'SkyPier',
      'code': 'check-in-skypier',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/skypier_twbx/checkin?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'Check-in',
      'name': 'Transfer',
      'code': 'check-in-desk-transfer',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/transfer_desks_skypier_ticketing_twbx/transfer_desk?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'Security Check',
      'name': 'T1 Departure',
      'code': 'security-check-departure',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/security_check_twbx/security_check?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'Security Check',
      'name': 'SkyPier',
      'code': 'security-check-skypier',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/security_check_twbx/security_check_skypier?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'Security Check',
      'name': 'Transfer',
      'code': 'security-check-transfer',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/transfer_security_twbx/security_check?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'Security Screening',
      'name': 'T1 Departure',
      'code': 'security-screen-departure',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/security_screen_twbx/security_screen?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'Security Screening',
      'name': 'SkyPier',
      'code': 'security-screen-skypier',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/security_screen_twbx/security_screen_skypier?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'Security Screening',
      'name': 'Transfer',
      'code': 'security-screen-transfer',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/transfer_security_twbx/security_screen?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'Immigration',
      'name': 'Departure',
      'code': 'immigration-departure',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/immigration_departure_twbx/immigration_departure?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'Immigration',
      'name': 'Arrival',
      'code': 'immigration-arrival',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/immigration_arrival_twbx/immigration_arrival?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'APM',
      'name': 'T1 Departure',
      'code': 'apm-departure',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/apm_departure_twbx/apm_departure?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'APM',
      'name': 'T1 Arrival',
      'code': 'apm-arrival',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/apm_departure_twbx/apm_arrival?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'APM',
      'name': 'SkyPier Sea-to-Air',
      'code': 'apm-skypier-sta',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/apm_departure_twbx/apm_skypier_sta?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'Apron Bus',
      'name': 'General',
      'code': 'apron-bus-departure',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/apv_departure_twbx/apv_departure?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'SkyPier',
      'name': 'Sea-to-Air',
      'code': 'skypier-sea-to-air',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/skypier_twbx/skypier?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'SkyPier',
      'name': 'Air-to-Sea',
      'code': 'skypier-ticketing',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/transfer_desks_skypier_ticketing_twbx/skypier_ticketing?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'Land Transportation',
      'name': 'Departure',
      'code': 'land-transport-departure',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/land_transport_twbx/land_transport?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'Trolley',
      'name': 'Landside Departure',
      'code': 'trolley-landside',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/departure_trolley_twbx/departure_trolley?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'Trolley',
      'name': 'Airside Departure',
      'code': 'trolley-airside',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/trolley_departure_airside_twbx/trolley_departure_airside?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'Trolley',
      'name': 'Airside Arrival',
      'code': 'arrival-airside-trolley',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/general_arrival_and_cleaning_twbx/arrival_airside_trolley?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'Cleaning',
      'name': 'General Departure',
      'code': 'cleaning-general',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/general_cleaning_departure_airside_twbx/general_cleaning_departure_airside?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'Cleaning',
      'name': 'General Arrival',
      'code': 'arrival-general-cleaning',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/general_arrival_and_cleaning_twbx/arrival_general_cleaning?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'Cleaning',
      'name': 'Toilet Departure',
      'code': 'cleaning-toilet',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/toilet_cleaning_airside_departure_twbx/toilet_cleaning_airside_departure?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'Cleaning',
      'name': 'Toilet Arrival',
      'code': 'toilet-cleaning-arrival',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/general_arrival_and_cleaning_twbx/toilet_cleaning_arrival?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'Cleaning',
      'name': 'Food Court',
      'code': 'cleaning-food-court',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/foodcourt_cleaning_twbx/foodcourt_cleaning?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    },
    {
      'category': 'Retail',
      'name': 'General',
      'code': 'retail-airside',
      'url': 'https://cnr-tcdm-tableau.candrholdings.com/trusted/###TICKET###/t/TCDM/views/retail_departure_airside_twbx/retail_departure_airside?###SCENARIO######PARAMETERS######WHATIF###',
      'height': 1500,
      'height_web': 3700,
    }
  ],
  whatIfList: []
};

const auth0Options = {
  container: 'auth0-container',
  configurationBaseUrl: 'https://cdn.auth0.com',
  languageDictionary: {
    title: ' ',
    enterpriseLoginIntructions: ' '
  },
  rememberLastLogin: false,
  theme: {
    logo: '/images/hkairport-curve-logo.png',
    primaryColor: 'white',
    authButtons: {
      'aahk-tcdm-adfs': {
        displayName: 'Sign in with HKAIRPORT',
        primaryColor: '#3D51E4',
        foregroundColor: '#fff',
        icon: 'https://cnrapp.blob.core.windows.net/ipa/tcdm/tcdm.png'
      },
      'aahk-adfs': {
        displayName: 'Sign in with HKAIRPORT',
        primaryColor: '#3D51E4',
        foregroundColor: '#fff',
        icon: 'https://cnrapp.blob.core.windows.net/ipa/tcdm/tcdm.png'
      }
    }
  }
};

const config = {
  productionEnv,
  uatEnv,
  devEnv,
  auth0Config,
  CREDENTIALS_KEY,
  AGREEMENT_KEY,
  MESSAGES_KEY,
  SETTINGS_KEY,
  tableau,
  tableauSite,
  defaultExpiresIn,
  auth0Options,
  sampleProfile
};

export default config;
