import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Snackbar,
} from "@material-ui/core";
import TopBar from "./components/TopBar";
import DataView from "./components/DataView";
import SideMenu from "./components/SideMenu";
import Login from "./components/Login";
import _ from "lodash";
import "./App.css";
import {
  markLastActiveTime,
  checkSavedSignIn,
  updateWhatIf,
  checkApiServerAccess,
  setApiServerPassword,
  // markAutoLogout,
  logout,
} from "./actions/loginActions";
import { changeDashboard } from "./actions/dashboardActions";
import MuiAlert from "@material-ui/lab/Alert";

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

const mapStateToProps = (state) => ({
  login: state.login,
  dashboard: state.dashboard,
});

const mapDispatchToProps = {
  markLastActiveTime,
  checkSavedSignIn,
  updateWhatIf,
  checkApiServerAccess,
  setApiServerPassword,
  // markAutoLogout,
  logout,
  changeDashboard,
};

const styles = (theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
  },
  content: {
    padding: 20,
    flexGrow: 1,
  },
});

// const AutoLogoutAlertDialog = ({open, onClose}) => (<Dialog
//   open={open}
//   onClose={onClose}
//   aria-labelledby="alert-dialog-title"
//   aria-describedby="alert-dialog-description"
//   disableBackdropClick
//   disableEscapeKeyDown
// >
//   <DialogTitle>{'Alert'}</DialogTitle>
//   <DialogContent>
//     <DialogContentText id="alert-dialog-description">
//       {'This session has reached maximum idle period. You will be logged out automatically.'}
//     </DialogContentText>
//   </DialogContent>
//   <DialogActions>
//     <Button autoFocus onClick={onClose} color="primary">
//       OK
//     </Button>
//   </DialogActions>
// </Dialog>)
class APIServerConfigDialog extends React.Component {
  constructor() {
    super();
    this._handlePasswordChange = this._handlePasswordChange.bind(this);
    this._handleConfirmPasswordChange =
      this._handleConfirmPasswordChange.bind(this);
    this._handleConfirmChange = this._handleConfirmChange.bind(this);
    this.state = {
      newPassword: "",
      confirmPassword: "",
      errorText: "",
    };
  }

  _handlePasswordChange(event) {
    this.setState({
      newPassword: event.target.value,
      errorText: "",
    });
  }

  _handleConfirmPasswordChange(event) {
    this.setState({
      confirmPassword: event.target.value,
      errorText: "",
    });
  }

  _handleConfirmChange() {
    const { newPassword, confirmPassword } = this.state;
    if (!confirmPassword || !newPassword || newPassword !== confirmPassword) {
      return;
    } else if (newPassword.length < 8) {
      this.setState({
        errorText: "Minimum length 8 characters",
      });
    } else {
      this.setState({
        newPassword: '',
        confirmPassword: ''
      }, () => this.props.onConfirm(newPassword))
    }
  }

  render() {
    const { newPassword, confirmPassword, errorText } = this.state;
    const { open, onClose } = this.props;

    return (
      <Dialog
        open={!!open}
        onClose={() => onClose()}
        aria-labelledby="api-config-dialog-title"
        aria-describedby="api-config-dialog-description"
      >
        <DialogTitle>{"Configure API server authentication"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="api-config-dialog-description">
            {"Please enter a password for API server authentication"}
          </DialogContentText>
          <TextField
            fullWidth
            error={!!this.state.errorText}
            type="password"
            label="New password"
            value={newPassword}
            helperText={errorText}
            onChange={this._handlePasswordChange}
            style={{ marginTop: 10, marginBottom: 10 }}
          />
          <TextField
            fullWidth
            error={!!this.state.errorText}
            type="password"
            label="Confirm password"
            value={confirmPassword}
            helperText={errorText}
            onChange={this._handleConfirmPasswordChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} color="primary">
            Cancel
          </Button>
          <Button
            disabled={
              !confirmPassword ||
              !newPassword ||
              newPassword !== confirmPassword
            }
            onClick={this._handleConfirmChange}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const LogoutConfirmationDialog = ({ open, onReply }) => (
  <Dialog
    open={open}
    onClose={() => onReply(false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle>{"Confirm"}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {"Are you sure to log out?"}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onReply(false)} color="primary">
        Cancel
      </Button>
      <Button onClick={() => onReply(true)} color="primary">
        Log out
      </Button>
    </DialogActions>
  </Dialog>
);

const AccessDeniedDialog = ({ dashboard, onClose }) => (
  <Dialog
    open={!!dashboard}
    onClose={() => onClose()}
    aria-labelledby="access-denied-dialog-title"
    aria-describedby="access-denied-dialog-description"
  >
    <DialogTitle>{"Access denied"}</DialogTitle>
    <DialogContent>
      <DialogContentText id="access-denied-dialog-description">
        {`Access to dashboard "${dashboard}" is denied`}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose()} color="primary">
        OK
      </Button>
    </DialogActions>
  </Dialog>
);

class App extends React.Component {
  constructor() {
    super();
    this._checkExpiry = this._checkExpiry.bind(this);
    this._updateWhatIf = this._updateWhatIf.bind(this);
    this._markLastActiveTimeThrottled = _.throttle(
      this._markLastActiveTime.bind(this),
      5000
    );
    this.state = {
      drawerOpened: false,
      confirmLogout: false,
      apiServerConfigDialogOpen: false,
    };
  }

  componentDidMount() {
    this.props.checkSavedSignIn();
    this.props.checkApiServerAccess();
    this._checkExpiry();
    this._updateWhatIf();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !prevProps.login.credentialsRestored &&
      this.props.login.credentialsRestored
    ) {
      console.debug(`credentials restored. check if direct link`);
      const { dashboards, dashboardList } = this.props.login;
      if (window.location.hash) {
        const match = /^#dashboard\/(.*)$/.exec(window.location.hash);
        if (match && match[1]) {
          console.debug(`direct link to dashboard ${match[1]}`);
          window.history.pushState(
            "",
            document.title,
            window.location.href.split("#")[0]
          );
          const targetDashboard =
            dashboardList && dashboardList.find((d) => d.code === match[1]);
          console.debug(
            `dashboard link exists: ${JSON.stringify(targetDashboard)}`
          );
          if (
            targetDashboard &&
            dashboards &&
            dashboards.includes(targetDashboard.code)
          ) {
            console.debug(`user has access right to the dashboard`);
            this.props.changeDashboard(targetDashboard.code);
          } else {
            console.debug(`user has no access right to the dashboard`);
            this.setState({
              accessDeniedDashboard: targetDashboard.code,
            });
          }
        }
      }
    }
    if (
      prevProps.login.apiServerPasswordResetStarted &&
      !this.props.login.apiServerPasswordResetStarted
    ) {
      if (this.props.login.apiServerPasswordResetFailed) {
        this.setState({
          error: this.props.login.apiServerPasswordResetFailed,
        });
      } else {
        this.setState({
          apiServerPasswordResetSuccess: "API server authentication has been configured successfully"
        })
      }
    }
  }

  componentWillUnmount() {
    this._expiryTimer && clearTimeout(this._expiryTimer);
  }

  _checkExpiry() {
    const { loggedInUser, lastActiveTime, idleTimeout, expiry } =
      this.props.login;
    const idleTimeoutDatetime = lastActiveTime + idleTimeout * 1000;
    const currentDatetime = new Date().getTime();
    if (
      loggedInUser &&
      idleTimeout > 0 &&
      currentDatetime > idleTimeoutDatetime
    ) {
      console.debug(
        `current (${currentDatetime}) > idle time (${idleTimeoutDatetime}). Max idle period reached, Current logged in user = ${loggedInUser}. will logout`
      );
      // this.props.markAutoLogout(true)
      this.props.logout(true);
    } else if (loggedInUser && idleTimeout > 0) {
      console.debug(
        `Current logged in user = ${loggedInUser}, current time (${currentDatetime}) < idle time (${idleTimeoutDatetime}). Idle timeout not reached yet`
      );
    }
    this._expiryTimer && clearTimeout(this._expiryTimer);
    this._expiryTimer = setTimeout(this._checkExpiry, 60 * 1000);
  }

  _updateWhatIf() {
    this._updateWhatIfTimer && clearTimeout(this._updateWhatIfTimer);
    this._updateWhatIfTimer = setTimeout(this._updateWhatIf, 5 * 60 * 1000);
    console.debug(`update whatif`);
    this.props.updateWhatIf();
  }

  _markLastActiveTime() {
    console.debug(`mark last active time`);
    this.props.markLastActiveTime();
  }

  _handleLogout(answer) {
    answer && this.props.logout();
  }

  render() {
    const { classes } = this.props;
    const {
      loggedInUser,
      loginStarted,
      loginFailed,
      credentialsRestored,
      userProfile,
      dashboards,
      dashboardList,
      apiServerAccessGranted
    } = this.props.login;
    const { selectedDashBoard } = this.props.dashboard;

    if (!credentialsRestored) {
      return (
        <Paper className={classes.root} elevation={1}>
          <CircularProgress size={100} />
        </Paper>
      );
    }

    return loggedInUser ? (
      <Paper className={classes.root} elevation={1}>
        <TopBar
          onDrawerClick={() =>{
            this.props.checkApiServerAccess();
            this.setState(
              {
                drawerOpened: !this.state.drawerOpened,
              },
              () => this._markLastActiveTimeThrottled()
            )}
          }
        />
        <SideMenu
          profile={userProfile}
          open={this.state.drawerOpened}
          userViewableDashboards={dashboards}
          selectedDashboard={selectedDashBoard}
          dashboardList={dashboardList}
          onClose={() => this.setState({ drawerOpened: false })}
          onSelect={(selectedDashboard) =>
            this.props.changeDashboard(selectedDashboard)
          }
          onLogout={() => this.setState({ confirmLogout: true })}
          showApiServerConfig={apiServerAccessGranted}
          onOpenAPIServerConfig={() =>
            this.setState({
              drawerOpened: false,
              apiServerConfigDialogOpen: true,
            })
          }
        />
        <div className={classes.content}>
          <DataView onReload={this._markLastActiveTimeThrottled} />
        </div>
        <APIServerConfigDialog
          open={this.state.apiServerConfigDialogOpen}
          onClose={(password) => {
            this.setState({
              apiServerConfigDialogOpen: false,
            });
          }}
          onConfirm={(password) => {
            this.setState(
              {
                apiServerConfigDialogOpen: false,
              },
              () => {
                console.debug(`Change password to "${password}"`);
                if (password) {
                  this.props.setApiServerPassword(password);
                }
              }
            );
          }}
        />
        <LogoutConfirmationDialog
          open={this.state.confirmLogout}
          onReply={(answer) => {
            this.setState(
              {
                confirmLogout: false,
              },
              () => this._handleLogout(answer)
            );
          }}
        />
        <AccessDeniedDialog
          dashboard={this.state.accessDeniedDashboard}
          onClose={() =>
            this.setState({
              accessDeniedDashboard: null,
            })
          }
        />
        {this.state.error && (
          <Snackbar
            open={!!this.state.error}
            autoHideDuraction={5000}
            onClose={() => this.setState({ error: null })}
            message={`API server password reset error. Please check and try again later. Error: ${this.state.error}`}
          />
        )}
        {this.state.apiServerPasswordResetSuccess && (
          <Snackbar
            open={this.state.apiServerPasswordResetSuccess}
            autoHideDuration={4000}
            onClose={() =>
              this.setState({
                apiServerPasswordResetSuccess: false,
              })
            }
          >
            <Alert
              onClose={() => this.setState({ apiServerPasswordResetSuccess: false })}
              severity="success"
            >
              API server password has been reset successfully
            </Alert>
          </Snackbar>
        )}
        Î
      </Paper>
    ) : (
      <Login />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(App));
