import React from 'react';
import { Select, MenuItem } from '@material-ui/core';

const ScenarioPicker = ({scenarios, selectedScenario, onChange}) => 
  <Select
    labelId="scenario-selector"
    value={selectedScenario}
    onChange={event => onChange(event.target.value)}
  >
    {scenarios && scenarios.map((scenario) => <MenuItem key={scenario.code} value={scenario.code}>{scenario.name}</MenuItem>)}
  </Select>

export default ScenarioPicker