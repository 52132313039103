// import dashboards from '../resources/dashboards'
import scenarioTemplate from '../resources/scenarioTemplate';

const initialState = {
  selectedDashBoard: 'general-overview',
  selectedScenario: 'normal',
  // dashboards: dashboards,
  scenarioTemplate: scenarioTemplate,
  getTicketStarted: false,
  getTicketFailed: null,
  tickets: {},
  reminders: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_DASHBOARD':
      return {
        ...state,
        selectedDashBoard: action.payload.dashboard
      };
    case 'CHANGE_SCENARIO':
      return {
        ...state,
        selectedScenario: action.payload.scenario
      };
    case 'RESET_SCENARIO':
      return {
        ...state,
        selectedScenario: initialState.selectedScenario
      };
    case 'GET_TICKET_START':
      return {
        ...state,
        getTicketStarted: true,
        getTicketFailed: null,
        tickets: { ...state.tickets }
      };
    case 'GET_TICKET_SUCCESS':
      return {
        ...state,
        getTicketStarted: false,
        getTicketFailed: null,
        tickets: {
          ...state.tickets,
          [action.payload.dashboard]: action.payload.ticket
        }
      };
    case 'GET_TICKET_FAILED':
      return {
        ...state,
        getTicketStarted: false,
        getTicketFailed: action.payload.error,
        tickets: { ...state.tickets }
      };
    case 'GET_REMINDERS':
      return {
        ...state,
      };
    case 'SHOW_REMINDERS_SUCCESS':
      return {
        ...state,
        reminders: action.payload.reminders
      };
    case 'SHOW_REMINDERS_FAILED':
      return {
        ...state,
      };
    default:
      return state;
  }
}

