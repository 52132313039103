import config from "../resources/config";
const { devEnv } = config;

const sampleProfile = {
  sub: "adfs|aahk-tcdm-adfs|JOHN_DOE",
  name: "John Doe (C&R)",
  picture: null,
};

const extractUsername = (tokenSubject) =>
  tokenSubject && tokenSubject.split("|").slice(-1)[0];

const allDashboards = [
  "check-in",
  "check-in-demand",
  "check-in-detailed",
  "departure-trolley",
  "general-overview",
  "immigration-departure",
  "land-transport",
  "security-check",
  "security-check-skypier",
  "security-screen",
  "security-screen-skypier",
  "skypier",
];

const initialState = {
  loginStarted: false,
  loginFailed: null,
  // userProfile: devEnv ? sampleProfile : null,
  userProfile: null,
  // loggedInUser: devEnv ? extractUsername(sampleProfile.sub) : null,
  loggedInUser: null,
  lastUsername: null,
  accessToken: devEnv ? "" : null,
  dashboards: devEnv ? allDashboards : [],
  dashboardList: [],
  whatIfList: [],
  idleTimeout: null,
  lastActiveTime: null,
  expiry: null,
  credentialsRestoreStart: false,
  credentialsRestored: false,
  autoLogout: false,
  apiServerPasswordResetStarted: false,
  apiServerPasswordResetFailed: null,
  apiServerAccessCheckStarted: false,
  apiServerAccessCheckFailed: null,
  apiServerAccessGranted: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_START":
      return {
        ...state,
        loginStarted: true,
        loginFailed: null,
        loggedInUser: null,
      };
    case "LOGIN_FAILED":
      return {
        ...state,
        loginStarted: false,
        loginFailed: action.payload.error,
        loggedInUser: null,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        loginStarted: false,
        loginFailed: null,
        userProfile: action.payload.profile,
        loggedInUser: action.payload.username,
        lastUsername: action.payload.username,
        accessToken: action.payload.accessToken,
      };
    case "AUTH0_SUCCESS":
      return {
        ...state,
        loginStarted: false,
        loginFailed: null,
        userProfile: action.payload.profile,
        loggedInUser: action.payload.loggedInUser,
        lastUsername: action.payload.loggedInUser,
        accessToken: action.payload.accessToken,
        dashboards: action.payload.dashboards,
        dashboardList: action.payload.dashboardList,
        whatIfList: action.payload.whatIfList,
        idleTimeout: action.payload.idleTimeout,
        expiry: action.payload.expiry,
      };
    case "LOGOUT":
      return {
        ...state,
        loginStarted: false,
        loginFailed: false,
        userProfile: null,
        loggedInUser: null,
        lastUsername: state.loggedInUser,
        accessToken: null,
      };
    case "MARK_LAST_ACTIVE_TIME":
      return {
        ...state,
        lastActiveTime: action.payload.lastActiveTime,
      };
    case "UPDATE_WHATIF":
      return {
        ...state,
        whatIfList: action.payload.whatIfList,
        whatIfListLastUpdated: action.payload.whatIfListLastUpdated,
      };
    // case 'AUTO_LOGOUT':
    //   return {
    //     ...state,
    //     autoLogout: action.payload.status
    //   }
    case "START_RESTORE_CREDENTIALS":
      return {
        ...state,
        credentialsRestoreStart: true,
        credentialsRestored: false,
      };
    case "RESTORE_CREDENTIALS":
      return {
        ...state,
        userProfile: action.payload.profile,
        loggedInUser: action.payload.loggedInUser,
        lastUsername: action.payload.loggedInUser,
        accessToken: action.payload.accessToken,
        dashboards: action.payload.dashboards,
        dashboardList: action.payload.dashboardList,
        whatIfList: action.payload.whatIfList,
        lastActiveTime: action.payload.lastActiveTime,
        idleTimeout: action.payload.idleTimeout,
        expiry: action.payload.expiry,
        credentialsRestored: true,
        credentialsRestoreStart: false,
      };
    case "API_SERVER_PASSWORD_RESET_START":
      return {
        ...state,
        apiServerPasswordResetStarted: true,
        apiServerPasswordResetFailed: null,
      };
    case "API_SERVER_PASSWORD_RESET_SUCCESS":
      return {
        ...state,
        apiServerPasswordResetStarted: false,
        apiServerPasswordResetFailed: null,
      };
    case "API_SERVER_PASSWORD_RESET_FAILURE":
      return {
        ...state,
        apiServerPasswordResetStarted: false,
        apiServerPasswordResetFailed: action.payload.error,
      };
    case "API_SERVER_ACCESS_CHECK_START":
      return {
        ...state,
        apiServerAccessCheckStarted: true,
        apiServerAccessCheckFailed: null,
        // apiServerAccessGranted: false,
      };
    case "API_SERVER_ACCESS_CHECK_SUCCESS":
      return {
        ...state,
        apiServerAccessCheckStarted: false,
        apiServerAccessCheckFailed: null,
        apiServerAccessGranted: action.payload.granted
      };
    case "API_SERVER_ACCESS_CHECK_FAILURE":
      return {
        ...state,
        apiServerAccessCheckStarted: false,
        apiServerAccessCheckFailed: action.payload.error,
        apiServerAccessGranted: false
      };
    default:
      return state;
  }
};
