import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Drawer } from "@material-ui/core";

// import pages from '../resources/pages'

import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import PlusIcon from "@material-ui/icons/Add";
import MinusIcon from "@material-ui/icons/Remove";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import DoneIcon from "@material-ui/icons/Done";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import UserAvatar from "./UserAvatar";
import _ from "lodash";

const styles = (theme) => ({
  list: {
    width: 400,
    cursor: "pointer",
    overflow: "hidden",
    zIndex: 100,
    paddingBottom: 100,
  },
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
  },
  content: {
    padding: 20,
    flexGrow: 1,
  },
  mainItem: {
    fontWeight: 900,
    fontSize: "larger",
  },
  dashboardItem: {
    "&:hover": {
      backgroundColor: "yellow",
    },
  },
  selectedDashboardItem: {
    backgroundColor: "cyan",
  },
  logoutItem: {
    position: "fixed",
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 1)",
    width: 400,
    cursor: "pointer",
    zIndex: 200,
  },
  logoutItemText: {
    color: "#f00",
    // color: '#fff'
  },
});

class SideMenu extends React.Component {
  constructor() {
    super();
    this.state = {
      dashboardCategoryExpanded: {},
    };
  }

  componentDidMount() {}

  _toggleDashboardCategory(dashboardCategoryName) {
    console.debug(`toggle ${dashboardCategoryName}`);
    console.debug(
      `current: ${this.state.dashboardCategoryExpanded[dashboardCategoryName]}`
    );
    console.debug(this.state);
    this.setState({
      dashboardCategoryExpanded: {
        // ...this.state.dashboardCategoryExpanded,
        [dashboardCategoryName]:
          !this.state.dashboardCategoryExpanded[dashboardCategoryName],
      },
    });
  }

  _findDashboardCategory(dashboardCode, dashboardList) {
    const targetDashboard = dashboardList.find((d) => d.code === dashboardCode);
    // console.debug(`dashboardCode = ${dashboardCode}, category = ${targetDashboard.category}`)
    return targetDashboard.category;
  }

  /*
    _findDashboardCategory(dashboardCode, pages) {
      const index = pages.findIndex(p => p.type === 'dashboard-link' && p.code === dashboardCode)
      if (index < 0) {
        return null
      }
      for (let i = index - 1; i >= 0; i--) {
        if (pages[i].type === 'dashboard-category') {
          return pages[i].name
        }
      }
      return null
    }
  */
  _shouldShowDashboard(dashboardCode, dashboardList) {
    const dashboardCategory = this._findDashboardCategory(
      dashboardCode,
      dashboardList
    );
    // console.debug(`dashboardCode = ${dashboardCode}, dashboardCategory = ${dashboardCategory}`)
    return (
      dashboardCategory &&
      this.state.dashboardCategoryExpanded[dashboardCategory]
    );
  }

  /*
    _shouldShowDashboard(dashboardCode, pages) {
      const dashboardCategory = this._findDashboardCategory(dashboardCode, pages)
      return dashboardCategory && this.state.dashboardCategoryExpanded[dashboardCategory]
    }
  */
  _handleLogout() {
    console.debug(`logout clicked`);
    this.props.onLogout();
  }

  render() {
    let {
      classes,
      profile,
      open,
      onClose,
      scenarios,
      onSelect,
      selectedDashboard,
      userViewableDashboards,
      dashboardList,
      showApiServerConfig,
    } = this.props;
    dashboardList = dashboardList.filter((r) =>
      userViewableDashboards.includes(r.code)
    );
    let pages = [
      {
        type: "scenario-picker",
        name: "scenario-picker",
      },
      {
        type: "heading1",
        name: "Dashboards",
        icon: "desktop-mac-dashboard",
      },
    ];
    const dashboardCategories =
      dashboardList && _.uniq(dashboardList.map((d) => d.category));
    dashboardCategories &&
      dashboardCategories.forEach((dc) => {
        pages.push({
          type: "dashboard-category",
          name: dc,
          icon: "view-dashboard",
        });
        dashboardList
          .filter(
            (d) => d.category === dc && userViewableDashboards.includes(d.code)
          )
          .forEach((d) => {
            pages.push({
              type: "dashboard-link",
              route: "dashboards",
              name: d.name,
              code: d.code,
              icon: "view-dashboard-outline",
            });
          });
      });
    pages.push({
      type: "menu",
      name: "Settings",
      route: "settings",
      icon: "settings",
    });
    return (
      <Drawer open={open} onClose={onClose}>
        <div role="presentation">
          <List className={classes.list}>
            <ListItem style={{ paddingLeft: 10 }}>
              <ListItemText
                primary={"TCDM"}
                primaryTypographyProps={{ className: classes.mainItem }}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <UserAvatar name={profile.name} picture={profile.picture} />
            </ListItem>
            {showApiServerConfig && (
              <React.Fragment>
                <Divider />
                <ListItem
                  style={{ paddingLeft: 10 }}
                  onClick={this.props.onOpenAPIServerConfig}
                >
                  <ListItemText primary={"Set up API server authentication"} />
                </ListItem>
              </React.Fragment>
            )}
            <Divider />
            <ListItem style={{ paddingLeft: 10 }}>
              <ListItemText
                primary={"Dashboards"}
                primaryTypographyProps={{ className: classes.mainItem }}
              />
            </ListItem>
            {pages.map((item, index) => {
              if (item.type === "dashboard-category") {
                return (
                  (item.type !== "dashboard-link" ||
                    this._shouldShowDashboard(item.code, dashboardList)) && (
                    <ListItem
                      style={{
                        paddingLeft:
                          item.type === "dashboard-category"
                            ? 15
                            : item.type === "dashboard-link"
                            ? 30
                            : 0,
                      }}
                      key={`${item.code}-${index}`}
                      onClick={() => {
                        if (item.type === "dashboard-category") {
                          this._toggleDashboardCategory(item.name);
                        } else {
                          onSelect(item.code);
                          onClose();
                        }
                      }}
                    >
                      <ListItemIcon>
                        {this.state.dashboardCategoryExpanded[item.name] ? (
                          <MinusIcon />
                        ) : (
                          <PlusIcon />
                        )}
                      </ListItemIcon>
                      <ListItemText primary={item.name} />
                    </ListItem>
                  )
                );
              } else if (
                item.type === "dashboard-link" &&
                this._shouldShowDashboard(item.code, dashboardList)
              ) {
                return (
                  <ListItem
                    key={`${item.code}-${index}`}
                    style={{ paddingLeft: 30 }}
                    onClick={() => {
                      if (userViewableDashboards.includes(item.code)) {
                        onSelect(item.code);
                        onClose();
                      }
                    }}
                    className={classes.dashboardItem}
                  >
                    <ListItemIcon>
                      <DashboardIcon />
                    </ListItemIcon>
                    {userViewableDashboards.includes(item.code) && (
                      <ListItemText primary={item.name} />
                    )}
                    {item.code === selectedDashboard && (
                      <ListItemSecondaryAction>
                        <DoneIcon />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                );
              }
            })}
            {/*<Divider/>
            <ListItem
              className={classes.logoutItem}
            >
              <ListItemIcon><DirectionsRunIcon/></ListItemIcon>
              <ListItemText primary={'LOG OUT'} primaryTypographyProps={{className: classes.logoutItemText}}/>
            </ListItem>*/}
          </List>
          <List className={classes.logoutItem}>
            <Divider />
            <ListItem onClick={() => this._handleLogout()}>
              <ListItemIcon>
                <DirectionsRunIcon />
              </ListItemIcon>
              <ListItemText
                primary={"LOG OUT"}
                primaryTypographyProps={{ className: classes.logoutItemText }}
              />
            </ListItem>
          </List>
        </div>
      </Drawer>
    );
  }
}

export default withStyles(styles)(SideMenu);
