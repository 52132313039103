import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

function UserAvatar({ name, picture }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Avatar
        alt={name}
        src={picture}
        className={classes.large}
      />
      {name}
    </div>
  )
}

export default UserAvatar